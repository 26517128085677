
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { ElNotification } from "element-plus";
import { hideModal } from "@/core/helpers/dom";
import DatePicker from "vue3-persian-datetime-picker";
import ApiService from "@/core/services/ApiService";
// import Tree from "@/components/phonebook/Tree.vue";
import UserNotHaveConnectedWa from "@/components/UserNotHaveConnectedWa.vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { Form } from "vee-validate";
import PhoneInput from "@/components/Phone/PhoneInput.vue";
import Platform from "@/components/Platform.vue";
import UploadFile from "@/components/uploadFiles/UploadFile.vue";
import Emoji from "@/components/Emoji.vue";
import {
  checkPackagePlatform,
  checkBotHasActivePlatform,
  getLocale,
} from "@/core/services/HelpersFun";
import { useI18n } from "vue-i18n";
import userInfo from "@/core/services/UserInfo";
import { platforms } from "@/store/pinia/Platforms";
import { AllBots } from "@/store/pinia/AllBots";
import { storeToRefs } from "pinia";
import { Countries } from "@/store/pinia/Countries";
import ShowFile from "@/components/ShowFile.vue";
import VoiceRecorder from "@/components/VoiceRecorder.vue";

export default defineComponent({
  name: "kt-bulks",
  components: {
    DatePicker,
    // Tree,
    UserNotHaveConnectedWa,
    PhoneInput,
    Platform,
    UploadFile,
    Form,
    Emoji,
    ShowFile,
    VoiceRecorder,
  },
  props: {
    version: {
      type: Number,
      default: 0,
    },
    phone: {
      type: Number,
      default: null,
    },
    clickedBot: {
      type: String,
      default: "",
    },
  },
  emits: ["refreshList", "close"],
  setup: function (props, { emit }) {
    const submitButton = ref<HTMLButtonElement | null>(null);
    const sendBulkModal = ref(null);
    const step = ref("input"); // input | show
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const disableItemsInUploading = ref({
      message: translate("uploading"),
      status: false,
      index: null,
    });

    const user = userInfo.getUser();
    const store = useStore();
    const advancedSetting = ref(false);
    const rule = ref({
      status: true,
      show: false,
    });

    const bulk = ref({
      messages: [
        {
          message: "",
          message_type: "message",
          origin_file_name: "",
          attachment_file: "",
          attachment_file_url: "",
          with_file: false,
        },
      ] as any,
      title: "",
      recipients: [] as any,
      phone_book_id: "",
      bot_id: "", //botId.value,
      platformsHolder: "" as any, // this in single
      platforms: [] as any, // this in single
      send_schedule: "",
      setting: {
        expired_date: "",
        start_clock: "",
        finish_clock: "",
        send_count: 3,
        break_time: 2,
        messages_count_per_day: 250,
      },
      with_country_code: true,
      country_code: user?.country?.phone_code,
      excel_phone_number_count: 0,
    });

    const handlePhoneNumbersCount = computed(() => {
      if (numberType.value === "excel" || numberType.value === "variable") {
        return bulk.value.excel_phone_number_count;
      } else if (numberType.value === "phonebook") {
        return treeRef.value?.selectedNumbers.length;
      } else {
        return recipients.value.split("\n").filter((item) => item !== "")
          .length;
      }
    });

    const errorsBag = ref({
      message: translate("Submitted entries are invalid"),
      data: {},
    });

    const getPhoneCode = (code) => {
      bulk.value.country_code = code;
    };

    const addNewItems = () => {
      bulk.value.messages.push({
        message: "",
        message_type: "message",
        attachment_file: "",
        with_file: false,
      });
    };

    const removeItem = (index) => {
      bulk.value.messages.splice(index, 1);
    };

    const fileUploadedCallBack = (file, activeIndex) => {
      bulk.value.messages[activeIndex].message_type = file.file_type;
      bulk.value.messages[activeIndex].attachment_file_url = file.url;
      if (file.origin_file_name)
        bulk.value.messages[activeIndex].origin_file_name =
          file.origin_file_name;

      if (bulk.value.messages[activeIndex].message_type === "file") {
        bulk.value.messages[activeIndex].with_file = true;
      } else {
        bulk.value.messages[activeIndex].with_file = false;
      }

      bulk.value.messages[activeIndex].attachment_file = file.path;
      if (file.only_as_file) {
        bulk.value.messages[activeIndex].with_file = true;
        ElNotification({
          title: translate("pay attention"),
          message: translate(
            "Your sent file exceeds the allowed size of images and videos in subscription and must be sent as a file"
          ),
          type: "warning",
        });
      }
    };

    watch(props, () => {
      if (props.clickedBot && props.clickedBot !== "")
        bulk.value.bot_id = props.clickedBot;
    });

    const AllBotsHolder = AllBots();
    const { botList } = storeToRefs(AllBotsHolder);
    const { fetchBotList } = AllBotsHolder;

    const selectedBot = computed(() => {
      if (!botList.value) return false;
      return botList.value.find((item) => item.bot_id === bulk.value.bot_id);
    });

    const countriesListHolder = Countries();
    const { countriesList } = storeToRefs(countriesListHolder);

    const submit = () => {
      errorsBag.value.data = {};
      if (!bulk.value.bot_id)
        errorsBag.value.data["bot_id"] = [
          translate("subscription selection is required"),
        ];
      if (bulk.value.platformsHolder === "")
        errorsBag.value.data["platform"] = [
          translate("Platform selection is required"),
        ];

      // if (bulk.value.title === "")
      //   errorsBag.value.data["title"] = [translate("title is required")];

      if (disableItemsInUploading.value.status === true) {
        errorsBag.value.data["uploading"] = [
          translate(
            "A file is uploading Please wait until the file is uploaded"
          ),
        ];
      }

      if (Object.keys(errorsBag.value.data).length > 0) {
        store.commit(Mutations.SET_ERROR, errorsBag.value);
        return false;
      }

      bulk.value.messages = bulk.value.messages.map((item, index) => {
        return {
          message: item.message,
          message_type: item.with_file ? "file" : item.message_type,
          attachment_file: item.attachment_file,
          origin_file_name: item.origin_file_name,
          attachment_file_url: item.attachment_file_url,
          with_file: item.with_file,
        };
      });

      // remove empty message
      bulk.value.messages = bulk.value.messages.filter((item) => {
        return item.message !== "" || item.message_type !== "message";
      });

      console.log(bulk.value.messages);

      bulk.value.platforms = [bulk.value.platformsHolder];
      handlePhoneNumbers();

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      ApiService.setHeader();
      ApiService.post("message/send", bulk.value)
        .then(() => {
          emit("refreshList");
          emit("close");
          // hideModal(sendBulkModal.value);
        })
        .catch((e) => {
          store.commit(Mutations.SET_ERROR, e.response.data);
          if (e.response!.status === 403) {
            ElNotification({
              title: translate("error"),
              message: translate(
                "The purchased package does not have the ability to send bulk messages Or you have reached the maximum number of submissions Or you have reached the maximum number of submissions"
              ),
              type: "error",
            });
            return;
          }
          ElNotification({
            title: translate("error"),
            message: translate("Error sending mass message"),
            type: "error",
          });
        })
        .finally(() => {
          if (submitButton.value) {
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          }
        });
    };

    const handlePhoneNumbers = () => {
      if (numberType.value === "manual")
        bulk.value.recipients = getRecipients();
      else if (numberType.value === "excel" || numberType.value === "variable")
        bulk.value["import_file"] = importExcelFileNumbers.value;
      else if (numberType.value === "phonebook") {
        // bulk.value.recipients = treeRef.value.getSelectedNumbers();

        bulk.value.recipients = treeRef.value?.selectedNumbers;

        // if (!bulk.value.recipients.length) {
        //   ElNotification({
        //     title: translate("error"),
        //     message: translate(
        //       "Please select your desired numbers from the phone book"
        //     ),
        //     type: "error",
        //   });
        //   return;
        // }
      }
    };

    // start phones numbers
    // const inputNumberType = ref(["manual", "excel", "phonebook", "variable"]);

    const inputNumberType = ref({
      manual: {
        name: "manual",
        icon: "art/art005.svg",
        title: "",
      },
      phonebook: {
        name: "phonebook",
        icon: "communication/com005.svg",
        title: "",
      },
      excel: {
        name: "excel",
        icon: "files/fil007.svg",
        title: "",
      },
      variable: {
        name: "variable",
        icon: "general/gen005.svg",
        title: "",
      },
    });
    const numberType = ref("manual");

    const treeRef = ref();
    const phonebooksTree = ref([]);
    const recipients = ref("");
    const importExcelFileNumbers = ref();

    const getRecipients = () => {
      return uniq(recipients.value.split("\n"));
    };

    const getPhonebooksTree = () => {
      ApiService.query("phonebook/tree", {}).then(({ data }) => {
        phonebooksTree.value = data.data.tree;
      });
    };

    const exelUploadedCallBack = (file) => {
      importExcelFileNumbers.value = file.path;
      bulk.value.excel_phone_number_count = file.excel_phone_number_count;
    };

    const uniq = (array) => {
      return Array.from(new Set(array));
    };

    const toEnglishDigits = (nums) => {
      // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
      var e = "۰".charCodeAt(0);
      nums = nums.replace(/[۰-۹]/g, function (t) {
        return t.charCodeAt(0) - e;
      });

      // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
      e = "٠".charCodeAt(0);
      nums = nums.replace(/[٠-٩]/g, function (t) {
        return t.charCodeAt(0) - e;
      });

      return nums;
      // recipients.value = nums;
    };

    const check = () => {
      // console.log(bulk.value.setting.messages_count_per_day);
    };

    // end phone numbers

    watch(
      () => props.version,
      () => {
        // getBots();
        getPhonebooksTree();
      }
    );
    const isFirefox = ref();

    const checkFileInUploading = (status, index) => {
      disableItemsInUploading.value.status = status;
      disableItemsInUploading.value.index = status === true ? index : null;
    };

    const selectedNumbers = computed(() => {
      return treeRef.value.map((item) => item.name);
    });

    const minDate = ref(new Date().toISOString().split("T")[0]);
    const minTime = ref("00:00");
    const maxTime = ref("23:59");

    watch(
      () => bulk.value.send_schedule,
      (newVal, oldVal) => {
        if (newVal === minDate.value) {
          minTime.value = new Date().toLocaleTimeString("en-US", {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          });
        }
      }
    );

    watch(
      () => bulk.value.setting.expired_date,
      (newVal, oldVal) => {
        if (newVal === minDate.value) {
          minTime.value = new Date().toLocaleTimeString("en-US", {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          });
        }
      }
    );

    onMounted(() => {
      // check browser
      isFirefox.value = navigator.userAgent.toLowerCase().includes("firefox");

      // getBots();
      fetchBotList();
      bulk.value.send_schedule = new Date().toISOString().split("T")[0];
      // bulk.value.setting.expired_date = new Date().toISOString().split("T")[0];
      getPhonebooksTree();

      // انتخاب پیش فرض
      if (!botList.value) {
        errorsBag.value.data["bot_id"] = translate(
          "You must have an active subscription to use Inboxino services"
        );
      } else {
        let firstActiveBot = botList.value.filter(
          (item) =>
            item.platforms.length > 0 &&
            item.platforms.filter((plat) => plat.status === "ready").length > 0
        );

        if (firstActiveBot.length > 0)
          bulk.value.bot_id = firstActiveBot[0].bot_id;
      }
    });
    const pos = ref();

    const emojiHelper = ref({
      activeIndex: 0 as number,
    });

    const getPos = (e) => {
      pos.value = e.target.selectionStart;
    };

    const addEmoji = (emoji) => {
      console.log("emoooooooo emited");
      console.log(bulk.value.messages[emojiHelper.value.activeIndex]);

      // bulk.value.messages[emojiHelper.value.activeIndex].message + emoji;
      let ar =
        bulk.value.messages[emojiHelper.value.activeIndex].message.split("");
      ar.splice(pos.value, 0, emoji);

      bulk.value.messages[emojiHelper.value.activeIndex].message = ar
        .toString()
        .replaceAll(",", "");
    };

    const platformsHolder = platforms();
    const { platformsList } = storeToRefs(platformsHolder);

    return {
      check,
      getPos,
      submitButton,
      submit,
      sendBulkModal,
      numberType,
      botList,
      recipients,
      addNewItems,
      removeItem,
      errorsBag,
      //for upload files
      exelUploadedCallBack,
      fileUploadedCallBack,

      hideModal,
      advancedSetting,
      phonebooksTree,
      treeRef,
      inputNumberType,
      rule,
      t,
      translate,
      selectedBot,
      getPhoneCode,
      bulk,
      disableItemsInUploading,
      checkFileInUploading,
      checkPackagePlatform,
      checkBotHasActivePlatform,
      toEnglishDigits,
      getLocale,
      selectedNumbers,
      minTime,
      maxTime,
      minDate,
      countriesList,
      addEmoji,
      emojiHelper,
      step,
      handlePhoneNumbersCount,
      platformsList,
      isFirefox,
    };
  },
});
