import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-body scroll-y pt-0 pb-15" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-12 col-md-6 my-2" }
const _hoisted_5 = { class: "mb-1 fs-7 text-gray-600 text-nowrap" }
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "fs-7 text-gray-600" }
const _hoisted_8 = { class: "col-sm-12 col-md-6 my-2" }
const _hoisted_9 = { class: "mb-1 fs-7 text-gray-600 text-nowrap" }
const _hoisted_10 = ["title"]
const _hoisted_11 = { class: "fs-7 text-gray-600" }
const _hoisted_12 = { class: "col-sm-12 col-md-6 my-2" }
const _hoisted_13 = { class: "mb-1 fs-7 text-gray-600" }
const _hoisted_14 = ["title"]
const _hoisted_15 = { class: "fs-7 text-gray-600" }
const _hoisted_16 = { class: "col-sm-12 col-md-6 my-2" }
const _hoisted_17 = { class: "mb-1 fs-7 text-gray-600" }
const _hoisted_18 = ["title"]
const _hoisted_19 = { class: "fs-7 text-gray-600" }
const _hoisted_20 = { class: "col-sm-12 col-md-6 my-2" }
const _hoisted_21 = { class: "required mb-1 fs-7 text-gray-600 text-nowrap" }
const _hoisted_22 = ["title"]
const _hoisted_23 = { class: "col-sm-12 col-md-6 my-2" }
const _hoisted_24 = { class: "required mb-1 fs-7 text-gray-600 text-nowrap" }
const _hoisted_25 = ["title"]
const _hoisted_26 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_picker = _resolveComponent("date-picker")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_center = _resolveComponent("center")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.translate("Start date of sending")) + " ", 1),
            _createElementVNode("i", {
              class: "fas fa-exclamation-circle ms-1 fs-7",
              "data-bs-toggle": "tooltip",
              title: 
                    _ctx.translate(
                      'The date you want your messages to start sending, for example, if you choose a date in three days, your messages will start sending in three days'
                    )
                  
            }, null, 8, _hoisted_6)
          ]),
          _createVNode(_component_date_picker, {
            clearable: "",
            "auto-submit": "",
            locale: _ctx.getLocale(),
            modelValue: _ctx.send_schedule,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.send_schedule) = $event)),
            type: "date",
            format: "YYYY-MM-DD",
            "display-format": "dddd jDD jMMMM jYYYY"
          }, null, 8, ["locale", "modelValue"]),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.startDate?.jalali ? _ctx.startDate?.jalali : _ctx.startDate), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", _hoisted_9, [
            _createTextVNode(_toDisplayString(_ctx.translate("End date of sending")) + " ", 1),
            _createElementVNode("i", {
              class: "fas fa-exclamation-circle ms-1 fs-7",
              "data-bs-toggle": "tooltip",
              title: 
                    _ctx.translate(
                      'The date you want your messages to start sending, for example, if you choose a date in three days, your messages will start sending in three days'
                    )
                  
            }, null, 8, _hoisted_10)
          ]),
          _createVNode(_component_date_picker, {
            clearable: "",
            "auto-submit": "",
            min: _ctx.send_schedule,
            locale: _ctx.getLocale(),
            modelValue: _ctx.setting.expired_date,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.setting.expired_date) = $event)),
            type: "date",
            format: "YYYY-MM-DD",
            "display-format": "dddd jDD jMMMM jYYYY"
          }, null, 8, ["min", "locale", "modelValue"]),
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.setting.expired_date
                    ? new Date(_ctx.setting.expired_date).toLocaleDateString("fa-IR")
                    : ""), 1)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("label", _hoisted_13, [
            _createTextVNode(_toDisplayString(_ctx.translate("Start time of sending")) + " ", 1),
            _createElementVNode("i", {
              class: "fas fa-exclamation-circle ms-2 fs-7",
              "data-bs-toggle": "tooltip",
              title: 
                    _ctx.translate(
                      'The time you want your messages to be sent every day, for example you want your messages to be sent every day at 8:00 am'
                    )
                  
            }, null, 8, _hoisted_14)
          ]),
          _createVNode(_component_date_picker, {
            clearable: "",
            "auto-submit": "",
            locale: _ctx.getLocale(),
            modelValue: _ctx.setting.start_clock,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.setting.start_clock) = $event)),
            type: "time",
            format: "HH:mm",
            "display-format": "HH:mm"
          }, null, 8, ["locale", "modelValue"]),
          _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.setting.start_clock), 1)
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("label", _hoisted_17, [
            _createTextVNode(_toDisplayString(_ctx.translate("Stop sending time")) + " ", 1),
            _createElementVNode("i", {
              class: "fas fa-exclamation-circle ms-2 fs-7",
              "data-bs-toggle": "tooltip",
              title: 
                    _ctx.translate(
                      'When you no longer want your messages to be sent For example if you dont want a message to be sent to the user after 23'
                    )
                  
            }, null, 8, _hoisted_18)
          ]),
          _createVNode(_component_date_picker, {
            disabled: !_ctx.setting.start_clock,
            clearable: "",
            "auto-submit": "",
            locale: "fa,en",
            modelValue: _ctx.setting.finish_clock,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.setting.finish_clock) = $event)),
            type: "time",
            format: "HH:mm",
            "display-format": "HH:mm"
          }, null, 8, ["disabled", "modelValue"]),
          _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.setting.finish_clock), 1)
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("label", _hoisted_21, [
            _createTextVNode(_toDisplayString(_ctx.translate("The number of messages sent in each period")) + " ", 1),
            _createElementVNode("i", {
              class: "fas fa-exclamation-circle ms-2 fs-7",
              "data-bs-toggle": "tooltip",
              title: `${_ctx.translate('in each period')}${
                    _ctx.setting.send_count
                  } ${_ctx.translate('messages')} ${_ctx.translate('sends')}.${_ctx.translate(
                    'Note that the lower this number the less likely the messenger will block you'
                  )}`
            }, null, 8, _hoisted_22)
          ]),
          _createVNode(_component_el_input, {
            size: "small",
            type: "number",
            style: {"width":"100%"},
            min: 1,
            max: 100,
            placeholder: 
                  _ctx.translate('The number of messages sent in each period')
                ,
            modelValue: _ctx.setting.send_count,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.setting.send_count) = $event))
          }, null, 8, ["placeholder", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("label", _hoisted_24, [
            _createTextVNode(_toDisplayString(_ctx.translate("Rest time between each period")) + " ", 1),
            _createElementVNode("i", {
              class: "fas fa-exclamation-circle ms-2 fs-7",
              "data-bs-toggle": "tooltip",
              title: `${_ctx.translate('Rest time between each period')}${
                    _ctx.setting.break_time
                  }${_ctx.translate('دقیقه است')}${_ctx.translate(
                    'and then starts sending again'
                  )}${_ctx.translate(
                    'Note that the lower this number the less likely the messenger will block you'
                  )}`
            }, null, 8, _hoisted_25)
          ]),
          _createVNode(_component_el_input, {
            size: "small",
            disabled: !_ctx.setting.send_count,
            type: "number",
            style: {"width":"100%"},
            placeholder: _ctx.translate('Rest time between each period'),
            min: 1,
            max: 100,
            modelValue: _ctx.setting.break_time,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.setting.break_time) = $event))
          }, null, 8, ["disabled", "placeholder", "modelValue"])
        ])
      ]),
      _createVNode(_component_center, null, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            disabled: _ctx.disabled_btn,
            class: "btn btn-primary mt-10",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.save()))
          }, _toDisplayString(_ctx.translate("save")), 9, _hoisted_26)
        ]),
        _: 1
      })
    ])
  ]))
}