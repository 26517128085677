
import { defineComponent , ref ,watch} from "vue";
import { translate } from "@/core/services/HelpersFun";

export default defineComponent({
  name: "kt_error_info_modal",
  props: {
    developError: {
      type: String,
      default: "",
    },
  },
  setup(props , {emit}) {

    const develop_error = ref(props.developError);
    watch(
      () => props.developError,
      () => {
        develop_error.value = props.developError
      }
    );
    return {
        develop_error,
      translate,

    };
  },
});
