import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  ref: "error_info_modal",
  tabindex: "-1",
  id: "kt_error_info_modal"
}
const _hoisted_2 = { class: "modal-dialog modal-lg" }
const _hoisted_3 = { class: "modal-content bg-gray-200" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = { class: "svg-icon svg-icon-2" }
const _hoisted_7 = { class: "modal-body" }
const _hoisted_8 = { style: {"direction":"ltr"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.translate("errorDetails")), 1),
          _createElementVNode("div", {
            class: "btn btn-icon btn-sm btn-active-light-primary ms-2",
            "data-bs-dismiss": "modal",
            "aria-label": "Close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pauseVideos && _ctx.pauseVideos(...args)))
          }, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.develop_error), 1)
        ])
      ])
    ])
  ], 512))
}