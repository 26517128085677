<template>
  <div>
    <div class="d-flex align-items-center">
      <el-popover
        :visible="audioData !== ''"
        :width="300"
        content="this is content, this is content, this is content"
      >
        <template #reference>
          <span> </span>
        </template>
        <template #default>
          <div class="d-flex align-items-center">
            <audio
              v-if="audioData"
              ref="audioPlayer"
              controls
              :src="audioData"
            ></audio>
            <el-tooltip
              placement="top"
              effect="light"
              :content="translate('fileUpload')"
              v-if="!recording"
            >
              <button
                @click="uploadFile"
                v-loading="uploading"
                class="btn btn-icon btn-circle btn-sm btn-custom btn-light-success border m-1"
              >
                <i class="bi bi-upload fs-3"></i>
              </button>
            </el-tooltip>
            <el-tooltip
              placement="top"
              effect="light"
              :content="translate('download')"
              v-if="!recording"
            >
              <button
                @click="downloadAudio"
                v-loading="uploading"
                class="btn btn-icon btn-circle btn-sm btn-custom btn-light-success border m-1"
              >
                <i class="bi bi-download fs-3"></i>
              </button>
            </el-tooltip>
            <el-tooltip
              placement="top"
              effect="light"
              :content="translate('delete')"
            >
              <button
                @click="audioData = ''"
                class="btn btn-icon btn-circle btn-sm btn-custom btn-light-danger border m-1"
              >
                <i class="bi bi-trash fs-3"></i>
              </button>
            </el-tooltip>
          </div>
        </template>
      </el-popover>
      <el-tooltip
        placement="top"
        effect="light"
        :content="translate('voice recording')"
        v-if="!recording"
      >
        <button
          @click="startRecording"
          class="btn btn-icon btn-custom btn-light border m-1 w-25px h-25px"
          type="button"
        >
          <i class="fs-3 bi bi-mic"></i></button
      ></el-tooltip>

      <el-tooltip
        placement="top"
        effect="light"
        :content="translate('stop recording')"
        v-if="recording"
      >
        <button
          @click="stopRecording"
          class="btn btn-icon btn-custom btn-light-danger animation-blink border m-1 w-25px h-25px"
          type="button"
        >
          <i class="fs-3 bi bi-stop-circle"></i>
        </button>
      </el-tooltip>

    </div>
  </div>
       <i v-if="audioPreview" style="position: absolute;bottom: 0px;color: red;font-size: 30px" class="bi bi-soundwave"></i>
</template>
<script>
import { ref } from "vue";
import axios from "axios";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  name: "kt-voice-recorder",
  emits: ["uploadSuccess"],
  props: {
    index: {
      type: String || Number,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const mediaRecorder = ref(null);
    const audioChunks = ref([]);
    const audioData = ref("");
    const audioPreview = ref(false);
    const recording = ref(false);
    const store = useStore();

    const startRecording = () => {
      audioData.value = "";
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          mediaRecorder.value = new MediaRecorder(stream);
         
          mediaRecorder.value.ondataavailable = (e) => {
            if (e.data.size > 0) {
              audioChunks.value.push(e.data);
              const blob = new Blob(audioChunks.value, {
                type: "audio/ogg; codecs=vorbis",
                mimeType: "audio/ogg",
              });
              audioData.value = URL.createObjectURL(blob);
            }
          };
          mediaRecorder.value.start();
          recording.value = true;
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    const stopRecording = () => {
      if (mediaRecorder.value && recording.value) {
        mediaRecorder.value.stop();
        recording.value = false;
      }
    };

    const uploading = ref(false);
    const uploadFile = async () => {
      uploading.value = true;
      try {
        const blob = new Blob(audioChunks.value, {
          type: "audio/ogg; codecs=vorbis",
          mimeType: "audio/ogg",
        });
        const formData = new FormData();
        formData.append("file", blob, "recorded_audio.ogg");

        const res = await axios.post(
          process.env.VUE_APP_UPLOAD_FILE_URL + "upload/file?inboxino=true",
          formData
        );

        emit(
          "uploadSuccess",
          {
            mime: res.data.data.mime,
            only_as_file: res.data.data.only_as_file,
            path: res.data.data.path,
            size: res.data.data.size,
            file_type: "audio", // image - video - audio - file - ...
            url: res.data.data.url,
          },
          props.index
        );
        audioData.value = "";
        audioPreview.value = true
        // اینجا می‌توانید با دسترسی به داده‌های ریسپانس، عملیات مورد نیاز را انجام دهید
      } catch ({ response }) {
        if (response && response.data)
          store.commit(Mutations.SET_ERROR, response.data);
      } finally {
        uploading.value = false;
      }
    };

    const downloadAudio = () => {
      if (audioData.value) {
        const link = document.createElement("a");
        link.href = audioData.value;
        link.download = "recorded_audio.ogg";
        link.click();
      }
    };

    return {
      translate,
      startRecording,
      stopRecording,
      audioData,
      recording,
      uploadFile,
      uploading,
      downloadAudio,
      audioPreview,
    };
  },
};
</script>

<style scoped>
audio {
  padding: 0px;
  border-radius: 5px;
  height: 37px;
  width: 190px;
}
</style>
