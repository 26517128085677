
import { defineComponent, onBeforeUnmount, onMounted, ref, watch, watchEffect } from "vue";
import { statusColor } from "@/core/services/HelpersFun";
import ChartStatusProgress from "@/components/widgets/mixed/ChartStatusReport.vue";
import { ElNotification } from "element-plus";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { checkAutoLogin } from "@/core/services/AutoLogin";
import UserInfo from "@/core/services/UserInfo";
import ErrorInfoModal from "@/components/modals/general/infoModal.vue";
import EditSettingModal from "@/views/pages/services/bulkMessage/component/editSettingModal.vue";
import InputNumbersWays from "@/views/pages/services/bulkMessage/component/InputNumbersWays.vue";
import { BulkMessages } from "@/store/pinia/BulkMessages.ts";

export default defineComponent({
  name: "multi-messages-report-modal",
  props: {
    messageId: {
      type: Number,
      default: null,
    },
    botId: {
      type: Number,
      default: null,
    },
  },
  components: {
    ChartStatusProgress,
    ErrorInfoModal,
    EditSettingModal,
    InputNumbersWays,
  },
  emits: ["changeStatus", "handleSizeChange", "size-change"],
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const route = useRoute();
    const selected = ref();
    const edit_modal = ref(false);
    const dialogVisible2 = ref(false);

    const loading = ref(true);
    const develop_error = ref("");
    const page = ref({
      telegram_page: 1,
      whatsapp_page: 1,
      eitaa_page: 1,
      bale_page: 1,
      rubika_page: 1,
    }) as any;
    const messageDetail = ref();
    const pagination = ref();
    const bot_id = ref("");

    const activeTabName = ref("whatsapp");
    const type = (type) => {
      switch (type) {
        case "message":
          return translate("text message");
        case "image":
          return translate("image");
        case "video":
          return translate("video");
        case "file":
          return translate("file");
      }
    };

    const getMessageRecipients = (id) => {
      loading.value = true;
      let endPoint =
        route.name === "bulk-message" ||  route.name === "archive-message"
          ? `message/${id}/recipients`
          : `admin/message/${id}/recipients`;
      ApiService.query(endPoint, {
        params: {
          telegram_page: page.value.telegram_page,
          whatsapp_page: page.value.whatsapp_page,
          eitaa_page: page.value.eitaa_page,
          bale_page: page.value.bale_page,
          rubika_page: page.value.rubika_page,
          group_by_platforms: true,
          with_dates: true,
          from_archive: route.name === "archive-message" ? 1 : 0
        },
      })
        .then(({ data }) => {
          messageDetail.value = data.data;
          bot_id.value = messageDetail.value.message.bot.bot_id;

          pagination.value = data.data.recipients.pagination;
          loading.value = false;
          if (Object.keys(messageDetail.value.recipients)[0]) {
            activeTabName.value = Object.keys(
              messageDetail.value.recipients
            )[0];
          }
        })
        .catch(() => {
          // ElNotification({
          //   title: translate("error"),
          //   message: translate("Error loading message information"),
          //   type: "error",
          // });
        });
    };

    const handleCurrentChange = (val: number) => {
      page.value[activeTabName.value + "_page"] = val;
      getMessageRecipients(messageDetail.value.message.id);
    };

    //  const handleCloseDialog = (done: () => void) => {
    //   dialogVisible2.value = false;
    //   done();
    // };

    const BulkMessagesHolder = BulkMessages();

    const { getItems } = BulkMessagesHolder;

    const recipientSaved = () => {
      getItems();
      dialogVisible2.value = false;
    };

    watch(
      () => props.messageId,
      (id) => {
        loading.value = true;
        getMessageRecipients(id);
      }
    );

watchEffect(async () => {
  getMessageRecipients(props.messageId);
  
})

    const interval = ref();

    onMounted(() => {
      interval.value = setInterval(() => {
        if (props.messageId) getMessageRecipients(props.messageId);
      }, 600000);
    });

    onBeforeUnmount(() => {
      clearInterval(interval.value);
    });

    const excelLink = (param) => {
      return (
        process.env.VUE_APP_BACK_URL +
        `message/${
          props.messageId
        }/report/download?status=${param}&api_token=${JwtService.getToken()}`
      );
    };

    const cancel = (recipient) => {
      ApiService.post(`message/recipient/change-status`, {
        recipient_id: recipient.id,
        status: "cancel",
      })
        .then(() => {
          getMessageRecipients(messageDetail.value.message.id);
        })
        .catch(() => {
          ElNotification({
            title: translate("error"),
            message: translate(""),
            type: "error",
          });
        });
    };

    const resend = (recipient) => {
      ApiService.post(`message/recipient/change-status`, {
        recipient_id: recipient.id,
        status: "pending",
      })
        .then(() => {
          getMessageRecipients(messageDetail.value.message.id);
        })
        .catch(() => {
          ElNotification({
            title: translate("error"),
            message: translate("Error resending message"),
            type: "error",
          });
        });
    };

    const refresh = () => {
      let oldStatus = messageDetail.value.status;
      getMessageRecipients(props.messageId);
      if (messageDetail.value.message.status !== oldStatus) {
        emit(
          "changeStatus",
          messageDetail.value.message.id,
          messageDetail.value.message.status
        );
      }
    };

    const userInfo = UserInfo.getUser();
    return {
      dialogVisible2,
      checkAutoLogin,
      userInfo,
      route,
      refresh,
      excelLink,
      messageDetail,
      loading,
      status,
      pagination,
      type,
      page,
      handleCurrentChange,
      resend,
      cancel,
      translate,
      statusColor,
      activeTabName,
      develop_error,
      selected,
      edit_modal,
      recipientSaved,
      bot_id,
    };
  },
});
