import { defineStore } from "pinia";
import { useStore } from "vuex";
import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";

export const BulkMessages = defineStore("bulkMessages", () => {
  const store = useStore();
  const route = useRoute();

  // states
  const bulkMessagesList = ref() as any;
  const loadingBulkMessages = ref(false);
  const page = ref(1);
  const KeyUpdateModals = ref(1);
  const statisticsData = ref();
  const pagination = ref();
  const user_id = ref<number | null>();

  // getters

  // actions
  const setUserId = (id) => {
    user_id.value = id;
  };

  const getItems = (platform = '', status = '', user_id = '', range = '') => {

    if (bulkMessagesList.value) KeyUpdateModals.value++;
    loadingBulkMessages.value = true;

    const endPointUrl =
      route.name === "bulk-message"
        ? "message"
        : "admin/message?with_user=true";

    const params = {
      page: page.value,
      // user_id: user_id.value, //user_id.value,
      with_dates: true,
      with_platforms: true,
      n: true,
      platform, status, user_id,
      from: range[0],
      to: range[1],
    };

    ApiService.setHeader();
    ApiService.query(endPointUrl, { params })
      .then(({ data }) => {
        bulkMessagesList.value = data.data.messages.data;
        statisticsData.value = data.data.statistics;
        pagination.value = data.data.messages.pagination;
      })
      .catch(() => {
        //
      })
      .finally(() => {
        loadingBulkMessages.value = false;
      });
  };

  const changeStatus = (id, newStatus) => {
    for (let i = 0; i < Object.keys(bulkMessagesList.value).length; i++) {
      const message = bulkMessagesList[i];
      if (message.id != id) continue;
      bulkMessagesList[i].status = newStatus;
    }
  };

  const stopChangeStatus = (id, status) => {
    ApiService.post(`message/${id}/change-status`, { status: status })
      .then(() => {
        getItems();
      })
      .catch(({ response }) => {
        if (response && response.data)
          store.commit(Mutations.SET_ERROR, response.data);
      });
  };

  return {
    // states
    bulkMessagesList,
    loadingBulkMessages,
    page,
    KeyUpdateModals,
    statisticsData,
    pagination,
    user_id,

    // actions
    getItems,
    changeStatus,
    stopChangeStatus,
  };
});
