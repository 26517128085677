<template>
  <div
    v-if="show"
    class="alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10"
  >
    <span class="svg-icon svg-icon-5tx svg-icon-danger mb-5">
      <inline-svg src="/media/icons/duotune/general/gen044.svg" />
    </span>
    <!--begin::Content-->
    <div class="text-center text-dark">
      <h1 class="fw-bolder mb-5">
        {{ translate("You do not have a subscription") }}
      </h1>
      <div
        class="separator separator-dashed border-danger opacity-25 mb-5"
      ></div>
      <div class="mb-9 fs-6 fw-bold">
        {{
          translate(
            "You must have an active subscription to use Inboxino services"
          )
        }}.
        {{
          translate(
            "Note that each subscription must have the required service packages"
          )
        }}.
        <br />
        <div class="mt-3">
          {{ translate("To view the guide") }}
          <router-link to="/help" class="fw-bolder me-2 fs-1 text-primary">
            {{ translate("Here") }} </router-link
          >{{ translate("clicked") }}
        </div>
      </div>
      <!--begin::Buttons-->
      <div class="d-flex flex-center flex-wrap">
        <router-link to="/bot-connections" class="btn btn-success m-2">
          {{ translate("Buy a subscription") }}
        </router-link>
        <button
          data-bs-dismiss="modal"
          class="btn btn-outline btn-outline-danger btn-active-danger m-2"
        >
          {{ translate("close") }}
        </button>
      </div>
      <!--end::Buttons-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translate } from "@/core/services/HelpersFun";

export default defineComponent({
  name: "kt-user-not-connected-to-wa",
  props: {
    show: { type: Boolean, default: true },
  },
  components: {},
  setup() {
    return {
      translate,
    };
  },
});
</script>

<style scoped></style>
