
import { defineComponent, onMounted, ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import ApiService from "@/core/services/ApiService";
import { translate } from "@/core/services/HelpersFun";

export default defineComponent({
  name: "kt-bulk-messages-and-api-chart",
  props: {
    type: { type: String },
    data: {
      type: Object,
      default: null,
    },
  },
  components: {},
  setup(props) {
    // chart
    const filter = ref("weekly");
    const chartData = ref();
    const options = ref();
    const series = ref();
    const chartType = ref("bar");

    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-primary");
    const secondaryColor = getCSSVariableValue("--bs-danger");
    watch(chartData, () => {
      options.value = {
        chart: {
          fontFamily: "inherit",
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: ["50%"],
            endingShape: "rounded",
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: chartData.value.chart.days,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (val) {
              return val + " پیام ";
            },
          },
        },
        colors: [baseColor, secondaryColor],
        grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      };
      series.value = [
        {
          name: "ارسال موفق",
          data: chartData.value.chart.sent,
        },
        {
          name: "ارسال ناموفق",
          data: chartData.value.chart.error,
        },
      ];
    });

    const getChartData = (filterParam) => {
      filter.value = filterParam;
      ApiService.get(
        `/dashboard/messages-chart?type=${props.type}&period=${filter.value}`
      )
        .then(({ data }) => {
          chartData.value = data.data.messages_chart;
        })
        .catch(() => {
          return;
        });
    };

    onMounted(() => {
      if (!props.data) getChartData("weekly");
      else chartData.value = props.data;
    });

    return {
      options,
      series,
      filter,
      getChartData,
      chartType,
      translate,
    };
  },
});
