
import { ElNotification } from "element-plus";
import { defineComponent, ref, watch } from "vue";
import DatePicker from "vue3-persian-datetime-picker";
import { translate } from "@/core/services/HelpersFun";
import {
  checkPackagePlatform,
  checkBotHasActivePlatform,
  getLocale,
} from "@/core/services/HelpersFun";
import ApiService from "@/core/services/ApiService";
// import { Mutations } from "@/store/enums/StoreEnums";
// import { useStore } from "vuex";

export default defineComponent({
  name: "edit_setting_modal",
  components: {
    DatePicker,
  },
  emit: ["close"],
  props: {
    selected: {
      type: Object,
      default: undefined,
    },
  },
  setup(prop, { emit }) {
    const loading = ref<boolean>(false);
    const send_schedule = ref();
    const startDate = ref();
    const disabled_btn = ref(false);
    const setting = ref({});

    watch(prop, () => {
      startDate.value = prop.selected?.send_schedule;
      setting.value = prop.selected?.setting;
    });

    const save = () => {
      disabled_btn.value =true
      ApiService.post("message/" + prop.selected?.id + "/change-setting", {
        send_schedule: send_schedule.value,
        setting: setting.value,
      })
        .then(() => {
          disabled_btn.value =false
           emit("close", true);
           ElNotification({
            title: translate("success"),
            message: translate("تنظیمات با موفقیت آپدیت شد"),
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          
        });
    };

    

    return {
      loading,
      send_schedule,
      startDate,
      setting,
      translate,
      getLocale,
      save,
      disabled_btn,
    };
  },
});
