
import { defineComponent, ref, onMounted } from "vue";
import MultiMessagesReportModal from "@/views/pages/services/bulkMessage/component/MultiMessagesReports.vue";
import BulkStatusWidget from "@/views/pages/services/bulkMessage/component/BulkStatusWidget.vue";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import SendBulkMessages from "@/views/pages/services/bulkMessage/component/SendBulkMessages.vue";
import DatePicker from "vue3-persian-datetime-picker";
import BulkMessagesAndApiChart from "@/components/BulkMessagesAndApiChart.vue";
import Rules from "@/components/Rules.vue";
import platform from "@/components/Platform.vue";
import Empty from "@/components/EmptyDataReplacement.vue";
import { useI18n } from "vue-i18n";
import UserAvatar from "@/components/UserAvatar.vue";
// import { checkPackagePlatform } from "@/core/services/HelpersFun";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import InputNumbersWays from "@/views/pages/services/bulkMessage/component/InputNumbersWays.vue";
import { BulkMessages } from "@/store/pinia/BulkMessages.ts";
import EditSettingModal from "@/views/pages/services/bulkMessage/component/editSettingModal.vue";
import { storeToRefs } from "pinia";
import {
  checkPackagePlatform,
  checkBotHasActivePlatform,
  getLocale,
} from "@/core/services/HelpersFun";

export default defineComponent({
  name: "multiple-messages",
  components: {
    Empty,
    DatePicker,
    MultiMessagesReportModal,
    SendBulkMessages,
    BulkStatusWidget,
    BulkMessagesAndApiChart,
    Rules,
    platform,
    UserAvatar,
    EditSettingModal,
    InputNumbersWays,
  },
  props: {
    user: {
      type: Object,
      default: undefined,
    },
  },
  emits: ["getSelectedBot", "size-change", "handleSizeChange", "setGif"],
  setup() {
    const route = useRoute();
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const dialogVisible = ref(false);

    // admin filter
    const select_platform = ref();
    const select_status = ref();
    const username = ref();
    const range_date = ref();
    const bulk_info = ref("");
    const info_plats = ref();
    const today = ref();

    const BulkMessagesHolder = BulkMessages();
    const {
      bulkMessagesList,
      loadingBulkMessages,
      page,
      KeyUpdateModals,
      statisticsData,
      pagination,
      user_id,
    } = storeToRefs(BulkMessagesHolder);

    const { getItems } = BulkMessagesHolder;

    const messageId = ref();
    const selected = ref();
    const edit_modal = ref(false);
    const reportMpodal = ref(false);
    const sendModal = ref(false);
    const botId = ref();
    const stopChangeStatus = (id, status) => {
      const btn = document.getElementById("btn" + id);
      if (btn) {
        btn.setAttribute("disabled", "true");
      }
      let url  = route.path.includes('management') ? `admin/message/${id}/change-status` : `message/${id}/change-status`
      ApiService.post(url, { status: status })
        .then(() => {
          getItems(
            select_platform.value,
            select_status.value,
            username.value,
            range_date.value
          );
          setTimeout(() => {
            if (btn) {
              btn.removeAttribute("disabled");
            }
          }, 4000);
        })
        .catch(({ response }) => {
          // if (response && response.data)
          //   store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    const loadingInfo = ref(false);

    const get_chatrs = () => {
      console.log(range_date.value);
      loadingInfo.value = true;
      ApiService.query("admin/message/get-chart", {
        params: {
          from: range_date.value[0],
          to: range_date.value[1],
          page: 1,
          platform: select_platform.value,
          status: select_status.value,
          user: username.value,
        },
      })
        .then((res) => {
          console.log(res.data);
          bulk_info.value = res.data.data.result;
          info_plats.value = Object.keys(res.data.data.result);
          loadingInfo.value = false;
        })
        .catch(({ response }) => {
          console.log(response);
          loadingInfo.value = false;
        });
    };

    const filtering = () => {
      info_plats.value = []
      getItems(
        select_platform.value,
        select_status.value,
        username.value,
        range_date.value
      );
      get_chatrs();
    };

    const excelLink = (id, param) => {
      return (
        process.env.VUE_APP_BACK_URL +
        `message/${id}/report/download?status=${param}&api_token=${JwtService.getToken()}`
      );
    };

    const handleCurrentChange = (val: number) => {
      page.value = val;
      getItems(
        select_platform.value,
        select_status.value,
        username.value,
        range_date.value
      );
    };

    const users = ref();
    const loadingSearchUser = ref(false);
    async function performSearch(searchTerm) {
      loadingSearchUser.value = true;
      const response = await ApiService.query("admin/user", {
        params: { q: searchTerm },
      });

      const results = await response;
      if (results && results.data) {
        loadingSearchUser.value = false;
        users.value = results.data.data.users.data;
      }
    }

    const handleCloseDialog = (done: () => void) => {
      dialogVisible.value = false;
      done();
    };

    const recipientSaved = () => {
      getItems(
        select_platform.value,
        select_status.value,
        username.value,
        range_date.value
      );
      dialogVisible.value = false;
    };

    const permission = ref();
    const userType = ref();
    onMounted(() => {
      // get_chatrs();
      today.value = new Date();

      setTimeout(() => {
        let per: any = localStorage.getItem("permissions");
        permission.value = JSON.parse(per);
        let user: any = localStorage.getItem("user_info");
        userType.value = JSON.parse(user).type;
      }, 3000);

      // setTimeout(getItems('teeest'), Math.floor(Math.random() * (3 - 1 + 1) + 1) * 1000);
      getItems(
        select_platform.value,
        select_status.value,
        username.value,
        range_date.value
      );
      setCurrentPageBreadcrumbs(
        translate("Bulk message (advertising)"),
        route.name !== "bulk-message" ? ["management"] : []
      );
    });

    return {
      select_platform,
      select_status,
      username,
      range_date,

      getLocale,

      get_chatrs,
      bulk_info,
      info_plats,
      today,
      filtering,
      bulkMessagesList,
      loadingBulkMessages,
      excelLink,
      pagination,
      messageId,
      selected,
      edit_modal,
      botId,
      handleCurrentChange,
      page,
      statisticsData,
      getItems,
      // changeStatus,
      stopChangeStatus,
      reportMpodal,
      sendModal,
      translate,
      checkPackagePlatform,
      KeyUpdateModals,
      route,

      users,
      loadingSearchUser,
      performSearch,
      user_id,
      dialogVisible,
      handleCloseDialog,
      recipientSaved,
      userType,
      permission,

      loadingInfo,
    };
  },
});
