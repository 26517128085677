import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column align-items-center" }
const _hoisted_2 = { class: "flex-grow-1" }
const _hoisted_3 = { class: "pb-3 d-flex flex-column text-center" }
const _hoisted_4 = { class: "text-gray-600" }
const _hoisted_5 = { class: "fs-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!
  const _component_Status = _resolveComponent("Status")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_apexchart, {
        class: "mixed-widget-4-chart",
        options: _ctx.chartOptions,
        series: _ctx.series,
        height: _ctx.chartHeight,
        type: "radialBar"
      }, null, 8, ["options", "series", "height"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, [
        _createTextVNode(_toDisplayString(_ctx.count > 0 ? Number(_ctx.count).toLocaleString() : 0) + " ", 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.translate("message")), 1)
      ]),
      _createVNode(_component_Status, { status: _ctx.btnText }, null, 8, ["status"])
    ])
  ]))
}